<template>
  <el-card shadow="always">
    <h4>Request Number</h4>
    <h1>{{$attrs.systemGeneratedInvoiceNo ? $attrs.systemGeneratedInvoiceNo : '_'}}</h1>
    <div  class="status-tag"  v-if="$attrs.nftRespDetails.isOverdue" style="color:#F26961;background-color: #FFE6E1;width:90%;margin-bottom:10px;margin-top:-5px">Over Due</div>
    <div class="flex-column" style="flex:1">
      <div style="min-height:50px">
        <div class="summary-block-title">
          <div class="flex-between-row" style="vertical-align:middle">
            <span style="line-height:28px">{{$attrs.isFinancier ? 'Supplier':'Financer' }} Information</span>
            <span v-if="!$attrs.isFinancier">
              <el-button v-if="!$attrs.isViewOnly" class="select-action-button" round outline size="mini"  @click="handleOpenFinancerDialog" >
              <p style="color:red; float:left;margin:0;">*</p>Select Financer
            </el-button> </span>
          </div>
          <el-divider></el-divider>
          <template v-if="!$attrs.isFinancier">
            <el-table
              v-if="$attrs.status === FINANCE_STATUS[0] || $attrs.view === 'CREATE_FINANCE'"
              :data="selectedFinancers"
              border
              :header-cell-style="HEADER_CELL_STYLE"
              :cell-style="CELL_STYLE"
              >
              <el-table-column label="No."  min-width="40px">
                <template v-slot="scope">
                  <h4>{{`${scope.$index + 1 }`}}</h4>
                </template>
              </el-table-column>
              <el-table-column label="Name"  min-width="150px">
                <template v-slot="scope">
                  <h4>{{scope.row.name }}</h4>
                </template>
              </el-table-column>
              <el-table-column  label="" width="50px" fixed="right" >
                <template v-slot="scope">
                  <el-popconfirm
                    confirm-button-text='Delete'
                    cancel-button-text='No, Thanks'
                    icon="el-icon-info"
                    icon-color="red"
                    title="Are you sure to delete this?"
                    @confirm="removeLine(scope.row,scope.$index)"
                  >
                    <el-button :disabled="$attrs.isViewOnly" slot="reference" size="small" icon="el-icon-delete" type="danger" circle ></el-button>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
            <div v-if="$attrs.financier" class="div-frac-12-avg sunmary-info" >
              <span>Name:</span>
              <span>{{ $attrs.financier ? $attrs.financier.name :"_"}}</span>
              <span>discount rate:</span>
               <span>{{$attrs.financier.discountRatePercentage ? $attrs.financier.discountRatePercentage+'%' : '_'}}</span>
              <span>lateChargesFee:</span>
              <span>{{$attrs.financier.lateChargesPercentage ? $attrs.financier.lateChargesPercentage+'%' : '_'}}%</span>
            </div>
          </template>
          <template v-if="$attrs.isFinancier">
            <div v-if="$attrs.supplier" class="div-frac-12-avg sunmary-info" >
              <span>Name:</span>
              <span>{{ $attrs.supplier ? $attrs.supplier.name :"_"}}</span>
              <span>Requestor:</span>
               <span>{{$attrs.supplier.requesterName ? $attrs.supplier.requesterName : '_'}}</span>
            </div>
          </template>
        </div>
      </div>
      <div style="min-height:100px; padding-top:10px;" :v-loading="$attrs.rightSummaryLoading" >
        <div class="summary-block-title margin-top-10">
            <span>Financing Summary</span>
            <el-divider></el-divider>
        </div>
        <div v-if="feesAndChargesList && feesAndChargesList.length > 0 " >
          <div v-for="item in feesAndChargesList" :key="item.id">
            <h4 style="padding-top:10px; color:#0F376A" >{{item.name}}</h4>
            <div class="div-frac-spec-2-1 sunmary-info">
              <span>Request Amount:</span>
              <span>{{numberWithCommas(item.amount, 2)}}</span>
              <span>Discount Rate Percentage:</span>
              <span>{{item.discountRatePercentage}}%</span>
              <span>Amount {{$attrs.isFinancier ? 'Payable' : 'Receivable'}}:</span>
              <span>{{numberWithCommas(item.amountReceivableAfterDiscountRate, 2)}}</span>
              <span>Late Charge Percentage:</span>
              <span>{{item.lateChargesPercentage}}%</span>
              <span>Late Charges Fee:</span>
              <span>{{numberWithCommas(item.lateChargesFeeBasedOnAmount, 2)}}</span>
              <span v-if="$attrs.isFinancier">Platform Fee Percentage:</span>
              <span v-if="$attrs.isFinancier">{{item.platformFeePercentage}}%</span>
              <span v-if="$attrs.isFinancier">Platform Fee:</span>
              <span v-if="$attrs.isFinancier">{{numberWithCommas(item.platformFee, 2)}}</span>
            </div>
          </div>
        </div>
        <div v-else> _ </div>
      </div>
    </div>
  </el-card>
</template>
<script>
import { mapState } from 'vuex'
import { capitalizeFirstLetter, numberWithCommas } from '@/utils/index.js'
import { HEADER_CELL_STYLE, CELL_STYLE, FINANCE_STATUS } from '@/constants.js'
export default {
  name: 'RightFinancerInfo',
  props: {
    selectedFinancers: {
      type: Array,
      required: false
    },
    summaryList: {
      type: Array,
      required: false
    }
  },
  data () {
    return {
      HEADER_CELL_STYLE,
      CELL_STYLE,
      FINANCE_STATUS
    }
  },
  computed: {
    ...mapState(['financeAccountMe']),
    feesAndChargesList () {
      if (this.summaryList && this.summaryList.length > 0) {
        if (this.$attrs.status === FINANCE_STATUS[0] && !this.financier) {
          if (this.selectedFinancers?.length > 0) {
            this.summaryList.forEach((el, index) => {
              // console.log('el, index', el, index);
              el.name = this.selectedFinancers.filter(financer => financer.id == el.id)[0]?.name
              // console.log('el, index', el.name)
              return el
            })
          }
        }
      }
      return this.summaryList ?? []
    }
  },
  methods: {
    capitalizeFirstLetter,
    numberWithCommas,
    handleOpenFinancerDialog () {
      this.$emit('update:isOpenSelectFinancerDialog', true)
    },
    removeLine (lineItem, index) {
      const financerList = this.selectedFinancers
      financerList.splice(index, 1)
      const _summaryList = this.summaryList
      _summaryList.splice(index, 1)
    }
  }
}
</script>
<style scoped lang="scss">
  @import "../index.scss";
  /deep/ .el-card__body {
    padding: 10px 5px;
  }
  /deep/ .el-table .el-table__row .el-table__cell:nth-child(even) {
    .cell{
    text-align: center;
  }
  }
</style>
